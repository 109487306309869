<script setup>
  import { ref, onMounted, computed } from 'vue';
  import axios from 'axios';

  // get blog url to compare against
  const blogUrl = document.querySelector('meta[name="blogUrl"]').getAttribute('content');

  // Define reactive variables
  let blogTitle = ref('');
  let blogContent = ref('');
  let blogThumbnail = ref('');
  let blogCreatedAt = ref('');

  // The function to load blog data
  async function loadBlogData() {
    try {
      const response = await axios.get('https://leads.lynniar.com/api/content-editor/site/24');
      const blog = response.data.blogs.find(blog => blog.url === blogUrl);
      
      if (blog) {
        blogTitle.value = blog.name;
        blogContent.value = blog.content;
        blogThumbnail.value = blog.thumbnail;
        blogCreatedAt.value = blog.created_at;
      } else {
        console.log('Blog not found');
      }
    } catch (error) {
      console.error('Error loading blog data:', error);
    }
  }

  // Method to format the date into DD/MM/YYYY
  const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString('en-GB', options);
  };

  onMounted(() => {
    loadBlogData();
  });
</script>



<template>
  <div class="content">

    <div id="blogPost">
      <div class="blogImg" :style="'background-image: url(' +  blogThumbnail  + ')'">
        <!-- <img :src="blog.image" alt="Blog Post Image"> -->
        <!-- {{ blog.image }} -->
      </div>
      <div class="blogTitle">
        <h2> {{ blogTitle }} </h2>
      </div>
      <div class="blogContent">                
        <div class="blogDescription">
          <p v-html="blogContent"></p>
        </div>                
        <div class="blogDate">
          <div class="date">Published on: {{ formatDate(blogCreatedAt) }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<!-- <script>
export default {
  props: {
    blogUrl: { 
      type: String, 
      required: true, 
    },
  },
  data() {
    return {
      blogTitle: '', 
    };
  },
  mounted() {
    this.loadBlogData();
  },
  methods: {
async loadBlogData() {
  try {
    console.log(this.blogUrl);  // This should output 'test-url-5'
    const response = await axios.get(`https://leads.lynniar.com/api/content-editor/site/24`);
    console.log(response.data.blogs)
    console.log(this.blogUrl)
    // const blog = response.data.blogs.find(blog => blog.url === this.blogUrl);
    const blog = response.data.blogs[0];
    
    if (blog) {
      this.blogTitle = blog.name;
      this.blogContent = blog.content;
      this.blogThumbnail = blog.thumbnail;
      this.blogCreatedAt = blog.created_at;
    } else {
      console.log('Blog not found');
    }
  } catch (error) {
    console.error('Error loading blog data:', error);
  }
}
  },
};
</script> -->

<style>
  body:has(#blog, #blog-item) {
    background: #FEF9EF !important;  
  }
</style>

<style scoped lang="scss">
  

  .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #FEF9EF;            
      min-height: 100vh;
      padding: 0 20px;
  }

  #blogPost {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 1000px;    
      margin: 100px 20px;    
      background: white;
      border-radius: 20px;
      /* min-height: calc(100vh - 200px); */
      overflow: hidden;
      border: 1px solid #faefdf;
  }

  #blogPost .blogTitle {
      display: flex;
      justify-content: flex-start;
      // width: calc(100% - 10%);
      width: 100%;
      padding: 2% 5%;
      border-bottom: 1px solid #faefdf;
  }

  #blogPost .blogImg {
      width: 100%;
      height: 400px;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FAEFDF;
      background-size: cover;
      background-position: center;
  }

  #blogPost .blogImg img {
      width: 100%;
  }

  #blogPost .blogContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;            
      gap: 10px;
      width: 100%;
      min-height: 36vh;
  }

  #blogPost .blogDescription {
      width: calc(100% - 10%);
      margin: 5%;
  }

  #blogPost .blogDate {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      padding: 2% 5%;
      border-top: 1px solid #faefdf;
      background: #FFE1BD;
  }

  @media (max-width: 840px) {
      .content {
          padding: 0 20px 0;
      }

      #blogPost .blogImg {
          height: 200px;
      }
  }
</style>
