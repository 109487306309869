<template>
  <div v-html="text"></div>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>
