import { createApp } from 'vue'   
import Header from './components/Header.vue'
import Footer from './components/Footer.vue' 
import BlogPage from './components/BlogPage.vue';
  
import 'bootstrap';
 

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
 
function generateUniqueId() {
    // Generate a random number and concatenate with current timestamp
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }
const uniqueId = generateUniqueId(); // You need to implement generateUniqueId() function
const uniqueId1 = generateUniqueId(); // You need to implement generateUniqueId() function

 createApp(Header).mount('#header')
 createApp(Footer).mount('#footer')
 createApp(BlogPage).mount('#blog-item')

// Conditional component imports and mounts
if (document.querySelector('#plan')) {
  import('./components/Plan.vue').then(({ default: Plan }) => {
    createApp(Plan, { uniqueId }).mount('#plan')
  });
}

if (document.querySelector('#planbottom')) {
  import('./components/Plan.vue').then(({ default: Plan }) => {
    createApp(Plan, { uniqueId: uniqueId1 }).mount('#planbottom')
  });
}

if (document.querySelector('#homeplan')) {
  import('./components/HomePlan.vue').then(({ default: HomePlan }) => {
    createApp(HomePlan, { uniqueId: uniqueId1 }).mount('#homeplan')
  });
}

if (document.querySelector('#faq')) {
  import('./components/Faq.vue').then(({ default: Faq }) => {
    createApp(Faq).mount('#faq')
  });
} 
 


if (document.querySelector('#thankyou')) {
  import('./components/Thankyou.vue').then(({ default: Thankyou }) => {
    createApp(Thankyou).mount('#thankyou')
  });
}

if (document.querySelector('#contact')) {
  import('./components/Contact.vue').then(({ default: Contact }) => {
    createApp(Contact).mount('#contact')
  });
}

if (document.querySelector('#paypalprocess')) {
  import('./components/PaypalProcess.vue').then(({ default: PaypalProcess }) => {
    createApp(PaypalProcess).mount('#paypalprocess')
  });
}

// if (document.querySelector('#ApplePay')) {
//   import('./components/ApplePay.vue').then(({ default: ApplePay }) => {
//     createApp(ApplePay).mount('#ApplePay')
//   });
// }

if (document.querySelector('#blog')) {
  import('./components/Blog.vue').then(({ default: Blog }) => {
    createApp(Blog).mount('#blog')
  });
};

import HomeContent from './components/HomeContent.vue';
import RightText from './components/RightText.vue';
import useHomeContent from './components/useHomeContent.js';


const { homeContent, fetchData } = useHomeContent();

fetchData().then(() => {
  const app = createApp({});

  app.component('HomeContent', HomeContent);
  app.component('RightText', RightText);

  app.mount('#app');

  // Mount HomeContent
  const homeContentApp = createApp(HomeContent, {
    leftContent: homeContent.value.left_content,
  });
  homeContentApp.mount('#hero_section_content_left_text');

  // Mount RightText1
  const rightText1App = createApp(RightText, {
    text:  homeContent.value.right_text1 ||  "<p>Unlimited Calls<br>And Texts</p>",
  });
  rightText1App.mount('#hero_section_content_right_text1');

  // Mount RightText2
  const rightText2App = createApp(RightText, {
    text: homeContent.value.right_text2 || "<p>No Credit Checks<br>Everyone Accepted!</p>",
  });
  rightText2App.mount('#hero_section_content_right_text2');

  // Mount RightText3
  const rightText3App = createApp(RightText, {
    text: homeContent.value.right_text3 || "<p>Chattr Perks &amp; <br>Discounts</p>",
  });
  rightText3App.mount('#hero_section_content_right_text3');

  // Mount RightText4
  const rightText4App = createApp(RightText, {
    text: homeContent.value.right_text4 || "<p> Roam Freely<br> in</p><span>40+</span><p>countries</p>",
  });
  rightText4App.mount('#hero_section_content_right_text4');
});

 