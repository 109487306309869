<template> 
     <div v-html="sanitizedLeftContent || fallbackHtml"></div>
</template>

<script>
 
export default {
  props: {
    leftContent: String,
  },
  computed: {
      
    sanitizedLeftContent() {
      return this.sanitizeHtml(this.leftContent || '');
    },
     fallbackHtml() {
      return `
        <p class="pb-0 sim-only-header-bottomtxt">
          <i class="fa-solid fa-circle-check fa-lg me-2" aria-hidden="true"></i>
          <b>Unlimited</b> UK calls and texts with all plans
        </p>
        <p class="pb-0 sim-only-header-bottomtxt">
          <i class="fa-solid fa-circle-check fa-lg me-2" aria-hidden="true"></i>
          Subscribe &amp; Save Plans for a further 10% off!
        </p>
        <p class="pb-0 sim-only-header-bottomtxt">
          <i class="fa-solid fa-circle-check fa-lg me-2" aria-hidden="true"></i>
          Enjoy Real 5G speeds at Zero extra cost
        </p>
      `;
     }
  },
   methods: {
    sanitizeHtml(html) {
      // Create a new DOM element to parse the HTML
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      // Remove script tags
      const scriptTags = doc.querySelectorAll('script');
      scriptTags.forEach(tag => tag.remove());

      // Optionally, remove other unwanted tags or attributes
      const aTags = doc.querySelectorAll('a');
      aTags.forEach(tag => {
        tag.removeAttribute('href');
        tag.removeAttribute('target');
      });

      // Return the sanitized HTML content
      return doc.body.innerHTML;
    },
  },
};
</script>