import { ref } from 'vue';

export default function useHomeContent() {
  const homeContent = ref(null);

  const fetchData = async () => {
    try { 
      const response = await fetch(process.env.VUE_APP_SUPASMART_URL+'api/gethomecontent');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log(data);
      // Check if the response contains valid data
      if (!data || Object.keys(data).length === 0) {
        homeContent.value = { 
          right_text1: "",
          right_text2: "",
          right_text3: "",
          right_text4: "",
          left_content:  "",
        };
      }
      
      homeContent.value = data;
    } catch (error) {
      console.error('Error fetching data:', error);
      
      // Fallback to static content
      homeContent.value = { 
        right_text1: "<p>Unlimited Calls<br>And Texts<\/p>",
        right_text2: "<p>No Credit Checks<br>Everyone Accepted!<\/p>",
        right_text3: "<p>Chattr Perks &amp; <br>Discounts<\/p>",
        right_text4: "<p> Roam Freely<br> in<\/p><span>40+<\/span><p>countries<\/p>",
        left_content: `<p class="pb-0 sim-only-header-bottomtxt">
          <i class="fa-solid fa-circle-check fa-lg me-2" aria-hidden="true"></i>
          <b>Unlimited</b> UK calls and texts with all plans</p>
          <p class="pb-0 sim-only-header-bottomtxt">
          <i class="fa-solid fa-circle-check fa-lg me-2" aria-hidden="true"></i>
          Subscribe &amp; Save Plans for a further 10% off!</p>
          <p class="pb-0 sim-only-header-bottomtxt">
          <i class="fa-solid fa-circle-check fa-lg me-2" aria-hidden="true"></i>
          Enjoy Real 5G speeds at Zero extra cost</p>`, 
      };
    }
  };

  return { homeContent, fetchData };
}